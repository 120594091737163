

export default {
  name: 'PaymentMethods',
  components: {
    Wrapper: () => import('@/components/Structure/Wrapper')
  },
  computed: {
    cssProps() {
      return {
        '--bg-color': this.bgColors,
        '--txt-color': this.txtColors
      }
    }
  },
  props: {
    content: {
      type: Array,
      default() {}
    },
    bgColors: {
      type: String,
      default: '#FFFFFF'
    },
    txtColors: {
      type: String,
      default: '#000000'
    }
  },
  data: () => ({
    hasMastercard: false,
    hasVisa: false,
    hasElo: false,
    hasAmex: false,
    hasDinersclub: false,
    hasHipercard: false,
    hasBoleto: false,
    hasPix: false
  }),
  mounted() {
    setTimeout(() => {
      this.setImagesDimensions()
          }, 7000)
    for (const item of this.content) {
      if (item.flag === 'mastercard' && item.status === true) {
        this.hasMastercard = true
      }
      if (item.flag === 'visa' && item.status === true) {
        this.hasVisa = true
      }
      if (item.flag === 'elo' && item.status === true) {
        this.hasElo = true
      }
      if (item.flag === 'amex' && item.status === true) {
        this.hasAmex = true
      }
      if (item.flag === 'dinersclub' && item.status === true) {
        this.hasDinersclub = true
      }
      if (item.flag === 'hipercard' && item.status === true) {
        this.hasHipercard = true
      }
      if (item.flag === 'boleto' && item.status === true) {
        this.hasBoleto = true
      }
      if (item.flag === 'pix' && item.status === true) {
        this.hasPix = true
      }
    }
  },
  methods: {
    setImagesDimensions() {
      const images = document.querySelectorAll('.reclame-aqui-mobile img');
      images.forEach(img => {
        img.width = 40
        img.height = 40
        img.alt = `Reclame-aqui ${img.alt}`
      })
    }
  }
}
